import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import SEO from '../components/seo'
import Section from '../components/Common/Section'
import Container from '../components/Common/Container'
import Typography from '../components/Common/Typography'
import Link from '../components/Common/Link'
import { Button } from '../components/Common/Button'
import RequestDemo from '../components/RequestDemo'
import ContactForm from '../components/contact-form'
import { useTranslations } from '../hooks/use-translations'

import * as s from '../pages/news.module.scss'

const PlanningPhase = () => {
  const { t } = useTranslations()
  const images = useStaticQuery(imagesQuery)
  const [modal, setModal] = React.useState(false)
  return (
    <>
      <SEO
        title="Planning phase"
        description="In the planning stage of a project, the design process is crucial as it sets the foundation for the construction phase. At the same time, it's important to ensure that the IT infrastructure is set up to facilitate seamless communication and data management. Quantity takeoff and project structuring are also important aspects that establish a framework for resource allocation and scheduling, which ultimately contribute to the efficient execution of the project."
      />
      <Section>
        <Container>
          <Typography variant="body1" color="gray" style={{ marginBottom: 8 }}>
            May 13, 2024
          </Typography>
          <Typography variant="h1" color="blue" size={40} mb={48}>
            Planning phase
          </Typography>
          <Typography variant="body1" size={18}>
            In the planning stage of a project, the design process is crucial as
            it sets the foundation for the construction phase. At the same time,
            it's important to ensure that the IT infrastructure is set up to
            facilitate seamless communication and data management. Quantity
            takeoff and project structuring are also important aspects that
            establish a framework for resource allocation and scheduling, which
            ultimately contribute to the efficient execution of the project.
          </Typography>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                BIM Modeling
              </Typography>
              <Typography variant="body1" size={18}>
                A CAD-to-BIM service involves converting 2D CAD drawings into 3D
                BIM models, enhancing the efficiency and accuracy of the design
                process. By digitizing existing CAD files, architects and
                engineers can leverage BIM's rich data capabilities for better
                visualization, clash detection, and coordination, ultimately
                leading to improved project outcomes and streamlined design
                workflows. Our expertise allows us to create BIM models of
                various disciplines: architectural, structural, and MEP in
                different{' '}
                <Link
                  href="https://bimforum.org/resource/lod-level-of-development-lod-specification"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  Levels of Development (LOD)
                </Link>{' '}
                from 200 to 450, adapting to your specific project requirements.
              </Typography>
            </div>
            <div>
              <StaticImage
                width={564}
                src="../assets/images/solutions/planning-phase/image-1.png"
                alt="BIM Modeling"
                title="BIM Modeling"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                Common Data Environment deployment
              </Typography>
              <Typography variant="body1" size={18}>
                A Common Data Environment (CDE) deployment service involves
                setting up, configuring, and managing CDE platforms, like
                ACC&nbsp;DOCS/ BIM360 or SIGNAX DOCS, where all project
                stakeholders can securely store, share, and collaborate on
                construction project information in real-time. This includes BIM
                managers' service of developing regulations and application
                instructions to specific project requirements As a certified{' '}
                <Link
                  href="https://www.autodesk.com/support/partners/signax/2113"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  Autodesk service partner
                </Link>{' '}
                and experts, we guarantee that by implementing a CDE,
                construction teams can streamline their design processes,
                document management, ensure version control, view BIM models in
                browser and improve communication throughout the project
                lifecycle. CDE facilitates efficient information exchange,
                reduces errors, and enhances project transparency, leading to
                smoother project delivery and better-informed decision-making.
              </Typography>
            </div>
            <div>
              <StaticImage
                width={564}
                src="../assets/images/solutions/planning-phase/image-2.png"
                alt="Common Data Environment deployment"
                title="Common Data Environment deployment"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                BIM Model audit
              </Typography>
              <Typography variant="body1" size={18}>
                Model checking service involves detecting clashes in the BIM
                model by predefined rules from EIR and verifying the presence
                and completeness of properties in building elements within the
                BIM model. This service ensures that potential conflicts between
                different building systems are identified early, reducing costly
                rework during construction. By leveraging customizable rule
                sets, our specialists streamline the quality assurance process,
                improving overall project efficiency and helping to deliver
                high-quality construction projects.
              </Typography>
            </div>
            <div>
              <StaticImage
                width={564}
                src="../assets/images/solutions/planning-phase/image-3.png"
                alt="BIM Model audit"
                title="BIM Model audit"
                placeholder="blurred"
              />
            </div>
          </div>
          <Typography variant="h2" size={24} mt={48} mb={16}>
            BIM Model classification
          </Typography>
          <Typography variant="body1" size={18}>
            Proper model classification is essential in the design stage of a
            project, facilitating streamlined processes and accurate analysis.
            SIGNAX BIM coordinators assign assembly codes in Revit streamlining
            this classification, enabling seamless organization and
            identification of components and cost estimation. A further
            classification in Navisworks through automated search sets, enabled
            by our specialists’ expertise, enhances efficiency in tasks such as
            volume calculations and generating bills of quantities and invoices.
            Utilizing internationally recognized classification systems such as
            Uniclass, Uniformat, Omniclass, and MasterFormat ensures project
            compatibility and consistency.
          </Typography>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                Quantity Takeoff
              </Typography>
              <Typography variant="body1" size={18}>
                The BIM-enabled Quantity Takeoff service offers practical
                solutions for construction projects. It allows efficient BOQ
                calculation, invoice generation, and seamless integration with
                Primavera schedules to track the percent completion of each
                work. SIGNAX BIM coordinators help to easily arrange projects
                using an Advanced Work Packaging (AWP) structure, which would
                help to flexibly gain volumes/money with the connection to
                buildings’ structure or construction project. Automating these
                processes streamlines workflow and enhances accuracy in cost
                estimation and billing.
              </Typography>
            </div>
            <div>
              <StaticImage
                width={564}
                src="../assets/images/solutions/planning-phase/image-4.png"
                alt="Quantity Takeoff"
                title="Quantity Takeoff"
                placeholder="blurred"
              />
            </div>
          </div>
          <Button
            size="large"
            className={cn('mx-auto', s.margin_top_48)}
            onClick={() => navigate('/bim-services/')}
          >
            Other BIM services
          </Button>
        </Container>
      </Section>
      <RequestDemo
        title={t('Know more')}
        bgImage={images.demoBg.childImageSharp.gatsbyImageData}
        moduleData={{
          btnText: t('Request a call'),
          onClick: () => setModal(true),
          btnColor: 'white',
        }}
      />
      {modal && (
        <ContactForm
          onClose={() => setModal(false)}
          title={t('Request a call')}
          requestType="Request a call"
        />
      )}
    </>
  )
}

export default PlanningPhase

const imagesQuery = graphql`
  query {
    demoBg: file(relativePath: { eq: "services-solutions-demo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`
